import fetchData from 'node-fetch'
import { handleLogEvent } from "./firebaseAnalytics";

const BASE_URL = process.env.API || process.env.NEXT_PUBLIC_API
const SERVER = process.env.SERVER

export const fetch = async (url) => {
  handleLogEvent("api_request", {
    method: "get",
    url: `${BASE_URL}/${url}`,
  })

  const response = await fetchData(`${BASE_URL}/${url}`, {
    headers: { 'Content-Type': 'application/json' }
  })

  if (response.status === 200) {
    const json = await response.json()
    handleLogEvent("api_success", {
      method: "post",
      url: `${BASE_URL}/${url}`,
      data: json
    })
    return json
  }
  handleLogEvent("api_error", {
    status: response.status,
    message: response.message,
    url: `${BASE_URL}/${url}`,
  })
  return null
}

export const fetchWithoutBase = async (url) => {
  handleLogEvent("api_request", {
    method: "post",
    url: `${SERVER}${url}`,
  })
  const response = await fetchData(`${SERVER}${url}`, {
    headers: { 'Content-Type': 'application/json' }
  })
  if (response.status === 200) {
    const json = await response.json()
    handleLogEvent("api_success", {
      method: "post",
      url: `${SERVER}${url}`,
      data: json
    })
    return json
  }
  handleLogEvent("api_error", {
    status: response.status,
    message: response.message,
    url: `${SERVER}${url}`,
  })
  return null
}

export const patch = async (url, formData) => {
  handleLogEvent("api_request", {
    method: "post",
    url: `${process.env.NEXT_PUBLIC_API}/${url}`,
  })
  const response = await fetchData(`${process.env.NEXT_PUBLIC_API}/${url}`, {
    body: formData,
    method: 'PATCH'
  })
  if (response.status) {
    const json = await response.json()
    handleLogEvent("api_success", {
      method: "post",
      url: `${process.env.NEXT_PUBLIC_API}/${url}`,
      data: json
    })
    return json
  }
  handleLogEvent("api_error", {
    status: response.status,
    message: response.message,
    url: `${process.env.NEXT_PUBLIC_API}/${url}`,
  })
  return null
}

export const post = async (url, data) => {
  handleLogEvent("api_request", {
    method: "post",
    url: `${process.env.NEXT_PUBLIC_API}/${url}`,
    payload: data
  })
  const response = await fetchData(`${process.env.NEXT_PUBLIC_API}/${url}`, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    method: 'POST'
  })
  if (response.status) {
    const json = await response.json()
    handleLogEvent("api_success", {
      method: "post",
      url: `${process.env.NEXT_PUBLIC_API}/${url}`,
      data: json
    })
    return json
  }

  handleLogEvent("api_error", {
    status: response.status,
    message: response.message,
    url: `${process.env.NEXT_PUBLIC_API}/${url}`,
  })
  return null
}

export const download = (url, filename) => {
  handleLogEvent("api_request", {
    method: "post",
    url,
  })

  fetchData(url)
    .then((res) => {
      return res.blob()
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
    .catch((err) => {
      console.error(err)
      handleLogEvent("api_error", {
        status: err?.status,
        message: err?.message,
      })
    })
}
